<template>
  <a
    class="card-link"
    :href="batches_show_url(batch.auction_url_friendly, batch.url_friendly)"
  >
    <div
      class="list-card is-flex is-align-items-center mb-3"
      :class="{
        'is-sold': batch.is_sold,
        warning: batch.bid_condition == 'covered',
      }"
    >
      <div class="batch-number mr-3">
        <span>Lote</span>
        <h3>{{ batch.number }}</h3>
      </div>

      <div
        class="batch-infos is-flex is-justify-content-space-between is-align-items-center"
      >
        <div
          class="is-flex is-flex-wrap-wrap is-flex-direction-column is-flex-gap-3"
        >
          <div
            v-for="animal in batch.animals"
            class="animal-content is-flex is-flex-direction-column mr-2"
          >
            <div class="animal-infos">
              <span class="animal-register">{{ animal.register }}</span>
              <span class="animal-title">{{ animal.title }}</span>
            </div>

            <div class="is-flex is-flex-wrap-wrap is-flex-gap-1 pt-1">
              <span v-if="animal.reproductive_status" class="tag">
                {{ animal.reproductive_status }}
              </span>
              <span v-if="animal.iabcz" class="tag">
                iABCZ {{ animal.iabcz }}
              </span>
              <span v-if="animal.birthdate" class="tag">
                {{ animal.birthdate }} meses
              </span>
            </div>
          </div>
        </div>

        <div
          class="batch-details is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
        >
          <div v-if="batch.animals_count > 1" class="batch-type">
            <LocalOffer />
            <span>{{ batch.batch_type }}</span>
          </div>

          <div
            v-if="batch.price"
            class="price-area is-flex is-flex-direction-column has-text-centered"
          >
            <p class="installments">
              {{ batch.installments }}x <span>R$</span>
              {{ pricePerAnimal }}
            </p>
            <p class="price">
              {{
                numberToCurrency(
                  (batch.price || batch.initial_price) / batch.animals_count,
                )
              }}
            </p>
            <span v-if="batch.animals_count > 1">(por animal)</span>
          </div>

          <p v-if="batch.is_sold" class="sold-warning">LOTE VENDIDO</p>

          <p v-else-if="!batch.price" class="bid-available">Ver informações</p>

          <p v-else-if="batch.event_type != 'auction'" class="buy-now">
            COMPRE AGORA
            <ShoppingCartIcon />
          </p>

          <p
            v-else-if="batch.bid_condition == 'available'"
            class="bid-available"
          >
            {{ BID_CONDITION[batch.bid_condition] }}
            <GavelIcon />
          </p>

          <p v-else-if="batch.bid_condition == 'made'" class="bid-made">
            {{ BID_CONDITION[batch.bid_condition] }}
            <CheckIcon />
          </p>

          <p v-else-if="batch.bid_condition == 'covered'" class="bid-covered">
            {{ BID_CONDITION[batch.bid_condition] }}
            <WarningIcon />
          </p>
        </div>
      </div>

      <ChevronRight class="chevron-right" />
    </div>
  </a>
</template>

<script>
import { batches_show_url } from "utils/routes";
import { numberToCurrency } from "utils/currencyConverter";

import ChevronRight from "icons/cached/chevron_right.svg";
import CheckIcon from "icons/cached/check.svg";
import LocalOffer from "icons/cached/local_offer.svg";
import WarningIcon from "icons/cached/warning.svg";
import GavelIcon from "icons/cached/gavel.svg";
import ShoppingCartIcon from "icons/cached/shopping_cart.svg";

import { computed } from "vue";

export default {
  components: {
    ChevronRight,
    CheckIcon,
    LocalOffer,
    WarningIcon,
    GavelIcon,
    ShoppingCartIcon,
  },
  props: {
    batch: Object,
  },
  setup(props) {
    const pricePerAnimal = computed(() => {
      return numberToCurrency(
        props.batch.price_per_animal / props.batch.installments,
      ).split(",")[0];
    });

    const BID_CONDITION = {
      available: "Dar Pré-lance",
      made: "Pré-lance",
      covered: "Pré-lance coberto!",
    };

    return {
      numberToCurrency,
      pricePerAnimal,
      batches_show_url,
      BID_CONDITION,
    };
  },
};
</script>
