<template>
  <div class="buttons-area is-flex mb-5">
    <button
      class="order-button has-text-weight-medium is-size-7 p-2 is-flex is-justify-content-space-between is-align-items-center is-hidden-desktop is-clickable"
      @click="toggleActive"
    >
      <p class="mr-1">
        Ordenar lotes por
        <span>{{ bottomSheetState.chosenOrdination }}</span>
      </p>
      <ImportExport />
    </button>

    <button
      class="view-buttons py-1 px-2 is-flex is-justify-content-space-between is-align-items-center"
    >
      <GridView
        class="is-clickable mr-5"
        :class="{ active: chosenView == 'grid' }"
        @click="changeView('grid')"
      />
      <ListIcon
        class="is-clickable"
        :class="{ active: chosenView == 'list' }"
        @click="changeView('list')"
      />
    </button>
  </div>

  <div class="ordination-area is-hidden-mobile has-text-centered mb-5">
    <p class="is-size-6 has-text-weight-semibold mb-2">Ordenar lotes por</p>

    <div
      class="ordination-tags is-flex is-flex-wrap-wrap is-justify-content-center pt-2"
    >
      <div
        v-for="ordination in bottomSheetState.ordinationTypes"
        class="tag has-text-weight-semibold is-size-7 is-clickable"
        :class="{ active: ordination.active }"
        @click="changeOrdination(ordination)"
      >
        {{ ordination.name }}
      </div>
    </div>
  </div>

  <div
    v-if="!isLoading"
    class="is-flex is-flex-wrap-wrap"
    :class="{ 'white-background py-3 px-2': chosenView == 'list' }"
  >
    <ProductCard
      v-if="chosenView == 'grid'"
      v-for="batch in requestBatches"
      :key="batch.id"
      :batch="batch.grid_view"
      :classes="['is-6-mobile', 'is-4-desktop', 'is-4-tablet', 'custom-space']"
      section="Event Batches"
      page="Event"
    />

    <ListCard
      v-else
      v-for="(batch, i) in requestBatches"
      :key="i"
      :batch="batch.list_view"
    ></ListCard>
  </div>

  <div
    v-else
    class="loading is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
  >
    <img class="mb-5" :src="loadingGif" alt="Gif de carregamento" />
    <p class="has-text-weight-medium is-size-6">Carregando resultados</p>
  </div>

  <div v-if="hasMore && !isLoading" class="column is-12 has-text-centered">
    <button
      class="button is-primary is-outlined"
      @click="seeMore(mixpanelData)"
    >
      Ver mais
    </button>
  </div>

  <AuctionOrdinationBottomSheet />
</template>

<script>
import { ref, onMounted } from "vue";
import {
  toggleActive,
  bottomSheetState,
  requestBatches,
  auctionUrlFriendly,
  seeMore,
  changeOrdination,
  hasMore,
  isLoading,
} from "./auctionBatchesContext";

import ProductCard from "components/product/ProductCard.vue";
import ListCard from "components/product/ListCard.vue";
import AuctionOrdinationBottomSheet from "components/auction/AuctionOrdinationBottomSheet.vue";

import loadingGif from "images/loading.gif";
import ImportExport from "icons/cached/import_export.svg";
import ListIcon from "icons/cached/list.svg";
import GridView from "icons/cached/grid_view.svg";

export default {
  components: {
    ProductCard,
    ListCard,
    AuctionOrdinationBottomSheet,
    ImportExport,
    ListIcon,
    GridView,
  },
  props: {
    batches: Array,
    auctionUrlFriendly: String,
    mixpanelData: Object,
    viewType: String,
    hasPrice: Boolean,
    hasAge: Boolean,
  },
  setup(props) {
    const chosenView = ref("");

    onMounted(() => {
      requestBatches.value = props.batches;
      chosenView.value = props.viewType;
      auctionUrlFriendly.value = props.auctionUrlFriendly;

      if (requestBatches.value.length < 30) {
        hasMore.value = false;
      }

      if (!window.location.href.includes("view")) {
        let params = new URLSearchParams(window.location.search);
        params.set("view-type", chosenView.value);

        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?${params.toString()}`,
        );
      } else {
        const params = new URLSearchParams(window.location.search);
        chosenView.value = params.get("view-type");
      }

      if (!props.hasPrice) {
        const types = ["highest_price", "lowest_price"];
        bottomSheetState.ordinationTypes =
          bottomSheetState.ordinationTypes.filter(
            (o) => !types.includes(o.type),
          );
      }

      if (!props.hasAge) {
        const types = ["highest_age", "lowest_age"];
        bottomSheetState.ordinationTypes =
          bottomSheetState.ordinationTypes.filter(
            (o) => !types.includes(o.type),
          );
      }
    });

    const changeView = (view) => {
      chosenView.value = view;

      let params = new URLSearchParams(window.location.search);
      params.set("view-type", view);

      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, "", newUrl);
    };

    return {
      hasMore,
      changeOrdination,
      seeMore,
      isLoading,
      requestBatches,
      chosenView,
      changeView,
      toggleActive,
      bottomSheetState,
      loadingGif,
    };
  },
};
</script>
