<template>
  <div class="columns is-mobile is-multiline crawled-auction-content">
    <div v-if="Object.keys(auctions).length" class="column verified-text is-hidden-mobile">
      <VerifiedIcon/> Eventos com assessoria da erural
    </div>

    <CrawledAuctionPreRegister
      :name="name"
      :email="email"
      :phone="phone"
      :lpConversion="lpConversion"
      :trackData="trackData"
      :auctionTags="auctionTags"
      :auction="currentAuctionModal"
      :open="openPreRegModal"
      @close="() => (openPreRegModal = false)"
    />

    <template v-for="(auctionByMonth, month, index) in auctions" :key="auctionByMonth">
      <div class="column is-12" :class=" { 'month-content' : (index > 0) }">
        <div
          class="p-3 month-section is-flex is-align-items-center is-justify-content-space-between has-text-weight-semibold mb-5 mt-3"
        >
          <span class="is-flex is-align-items-center">
            <DataPickIcon class="mr-2" />
            {{ month.split(",")[0].replace(/[^a-zA-ZÇ-ç ]/g, "") }}
          </span>
          <span class="has-text-weight-medium has-text-grey">
            {{ auctionByMonth.length }} eventos encontrados
          </span>
        </div>
      </div>

      <template v-for="auction in auctionByMonth" :key="auction.id">
        <div class="column is-12-mobile is-6-tablet is-4-desktop py-2">
          <AuctionCard
            :breed="auction.breed"
            :date="auction.date"
            :day="auction.auction_day"
            :end_day="auction.auction_end_day"
            :month="auction.month_and_year[0].slice(0, 3)"
            :end_month="auction.end_month_name.slice(0, 3)"
            :hour="auction.auction_hour"
            :title="auction.title"
            :auctioneer="
              auction.auctioneer ? auction.auctioneer : auction.channel
            "
            :channel="auction.channel"
            :link="
              auction.auction
                ? auctions_show_path(auction.auction.url_friendly)
                : ''
            "
            :location="auction.location"
            :category="auction.category"
            :banner="auction.card_banner"
            :age="auction.auction_age"
            :featured="auction.auction_featured"
            @click="handleClick(auction)"
          />
        </div>
      </template>
    </template>
    <div
      v-if="isLoading"
      class="column is-12 is-flex is-justify-content-center"
    >
      <div class="button is-borderless is-loading is-size-3">Loading</div>
    </div>

    <div
      v-if="!Object.keys(auctions).length"
      class="column is-4-desktop is-12-mobile is-offset-4-desktop is-flex not-found is-flex-direction-column is-align-items-center"
    >
      <img :src="chicoSadFaceImg" alt="" />
      <div class="px-4 py-4 is-size-6-desktop message mb-2">
        Produtor, não encontrei nenhum evento com essas características.
        <span class="has-text-weight-semibold">Tente de novo:</span>
      </div>
      <div
        class="button is-primary is-fullwidth mb-4 px-4 is-hidden-desktop"
        @click="modalFilterOpen = true"
      >
        Mais Filtros
      </div>
      <button class="button is-outline px-4 is-fullwidth" @click="cleanFilters">
        Limpar Filtros
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import useScrollPosition from "hooks/useScrollPosition";
import { auctions_show_path } from "utils/routes";
import { useCrawledAuctions } from "hooks/useCrawledAuctions";

import AuctionCard from "./CrawledAuctionCard.vue";
import CrawledAuctionPreRegister from "./CrawledAuctionPreRegister.vue";

import DataPickIcon from "icons/cached/event.svg";
import VerifiedIcon from "icons/cached/verified.svg";
import chicoSadFaceImg from "images/chico/chico_sad_face.png";

export default {
  components: {
    AuctionCard,
    DataPickIcon,
    VerifiedIcon,
    CrawledAuctionPreRegister,
  },
  props: {
    firstPageAuctions: Object,
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
    trackData: Object,
    auctionTags: Array,
  },
  setup(props) {
    const { x, y } = useScrollPosition();
    const currentAuctionModal = ref({});

    const {
      auctions,
      page,
      isLoading,
      modalCardIsOpen,
      getCrawledAuctions,
      filter,
      cleanFilters,
      modalFilterOpen,
      openPreRegModal,
      sendEventMixpanelTrack
    } = useCrawledAuctions();

    const handleClick = (auction) => {
      if (auction.auction) {
        window.location = auctions_show_path(
          auction.auction.url_friendly,
        );
      } else {
        currentAuctionModal.value = auction;
        modalCardIsOpen.value = true;
      }

      sendEventMixpanelTrack('Scheduler - Crawled Event Clicked', auction);
    };

    onMounted(() => {
      let filters = decodeURI(window.location.href).split("&");

      if (filters.length > 1) {
        isLoading.value = false;
        onMountUrl(filters);
      } else {
        auctions.value = props.firstPageAuctions;
        isLoading.value = false;
      }
    });

    watch(y, () => {
      if (
        document.documentElement.scrollTop + window.innerHeight >
          document.documentElement.offsetHeight - 170 &&
        !isLoading.value
      ) {
        page.value++;
        getCrawledAuctions();
      }
    });

    const onMountUrl = (filters) => {
      if (filters.length > 1) {
        filters.forEach((item) => {
          var param = item.split("=");

          switch (param[0]) {
            case "Data":
              filter.date = param[1];
              break;

            case "Estado":
              filter.state = param[1].split(",");
              break;

            case "Categoria":
              let categoryObj = {
                Bovinos: "bovine",
                Equídeos: "equidae",
                Ovinos: "ovine",
                Caprinos: "caprine",
              };

              param[1].split(",").forEach((item) => {
                filter.category.push(categoryObj[item]);
              });
              break;

            case "Raças":
              filter.breed = param[1].split(",");
              break;

            case "Frete":
              let shippingObj = {
                Sem_informações: "no_informations",
                Frete_grátis: "free_shipping",
                Frete_facilitado: "facilitated_shipping",
                Frete_por_conta_do_comprador: "shipping_by_buyer",
              };

              param[1].split(",").forEach((item) => {
                filter.shipping.push(shippingObj[item]);
              });
              break;
          }
        });

        getCrawledAuctions();
      }
    };

    return {
      auctions,
      auctions_show_path,
      currentAuctionModal,
      isLoading,
      handleClick,
      modalFilterOpen,
      cleanFilters,
      openPreRegModal,
      chicoSadFaceImg,
    };
  },
};
</script>
