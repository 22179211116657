<template>
  <div class="pre-registration-form">
    <span class="title is-block has-text-centered has-text-weight-bold">
      Quer um lote parecido?
    </span>
    <span class="description is-block has-text-centered">
      Este lote já foi vendido, mas
      <span class="has-text-weight-semibold">registre-se</span> para receber
      atualizações deste evento e semelhantes!
    </span>

    <AuctionPreRegistrationForm
      :name="name"
      :email="email"
      :phone="phone"
      :lpConversion="lpConversion"
      :trackData="trackData"
      :auctionTags="auctionTags"
      :page="page"
      :mixpanelData="mixpanelData"
    />
  </div>
</template>

<script>
import AuctionPreRegistrationForm from "./AuctionPreRegistrationForm.vue";

export default {
  components: {
    AuctionPreRegistrationForm,
  },
  props: {
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
    trackData: Object,
    auctionTags: Array,
    page: String,
    mixpanelData: Object
  },
};
</script>
