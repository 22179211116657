<template>
  <div class="cover is-clipped is-4" @click="changeBannerVisibility">
    <img :src="small_banner" :alt="title"/>
  </div>
  <div v-if="openBanner" class="modal auction-info-modal" :class="{ 'is-active': openBanner }">
    <div class="modal-background" @click="changeBannerVisibility" />
    <div class="modal-content banner-modal">
      <img :src="medium_banner" :alt="title"/>
      <button
        class="button is-warning is-fullwidth mb-4"
        @click="openModalAndTrack($event)"
      >
        {{ auction.cta_text_button || "Tenho interesse" }}
      </button>
      <AuctionPreRegModal
        :name="name"
        :email="email"
        :phone="phone"
        :page="page"
        :formDisplay="formDisplay"
        :section="section"
        :lpConversion="lpConversion"
        :trackData="trackData"
        :auctionTags="auctionTags"
        :mixpanelData="mixpanelData"
        :open="openPreRegModal"
        :bannerBtn="true"
        @close="() => (openPreRegModal = false)"
      />
    </div>
  </div>
</template>

<script>
import { ref, toRefs, watch } from "vue";
import AuctionPreRegModal from "./AuctionPreRegModal.vue";
import { preRegistrationState } from "components/registration_flow/preRegistrationContext";

export default {
  components: {
    AuctionPreRegModal,
  },
  props: {
    small_banner: String,
    medium_banner: String,
    title: String,
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
    trackData: Object,
    auctionTags: Array,
    auction: Object,
    auctionFiles: Object,
    mixpanelData: Object,
    page: String,
    formDisplay: String,
    section: String,
  },
  setup(props){
    const openBanner = ref(false);

    const changeBannerVisibility = () => {
        openBanner.value = !openBanner.value;
    }

    const openPreRegModal = ref(false);

    const { conversion } = toRefs(preRegistrationState);

    watch(conversion, () => {
      openPreRegModal.value = conversion ? false : true;
    });

    const openModalAndTrack = (e) => {
      openPreRegModal.value = true;

      let element = e.currentTarget;
      let btColor = window.getComputedStyle(element, null).getPropertyValue('background-color');
      btColor = btColor.replace(/[rgb() ]/g, '').split(',');

      let btProps = {
        'Text': element.textContent,
        'Color': rgbToHex(btColor),
        'Section': 'Infos Container'
      }

      let eventName = 'Event - Banner Clicked';

      mixpanel.track(eventName, { ...props.mixpanelData, ...btProps });
    }
    const componentToHex = (c) => {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    }

    const rgbToHex = (array) => {
      return "#" + componentToHex(parseInt(array[0])) + componentToHex(parseInt(array[1])) + componentToHex(parseInt(array[2]));
    }

    return {
      openBanner,
      changeBannerVisibility,
      openPreRegModal,
      openModalAndTrack,
    };
  }
}
</script>