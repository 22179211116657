<template>
  <div />
</template>

<script>
import { onMounted } from "vue";
export default {
  props: {
    trackData: Object,
    auctionTags: Array,
  },
  setup(props) {
    onMounted(() => {
      if (window.fbq) {
        window.fbq(
          "trackCustom",
          `${props.trackData.origin} - Visualizou`,
          props.trackData,
        );

        props.auctionTags.forEach(function (el, i) {
          window.fbq(
            "trackCustom",
            `${props.trackData.origin} - Visualizou ${el}`,
            props.trackData,
          );
        });
      }
    });
  },
};
</script>
