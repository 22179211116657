<template>
  <a class="selection-link" :href="batch.link" :id="`productId${batch.id}`">
    <div class="selection-card">
      <swiper
        v-if="batch.animals_quantity > 1"
        :pagination="{ clickable: true }"
        :slidesPerView="1"
        loop
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
      >
        <div class="swiper-button-content">
          <div class="swiper-button-next">
            <ChevronRight />
          </div>
          <div class="swiper-button-prev">
            <ChevronLeft />
          </div>
        </div>

        <swiper-slide>
          <div class="card-image">
            <div class="card-background" />
            <span v-if="batch.avg_iabcz" class="card-iabcz">
              iABCZ: {{ batch.avg_iabcz }}
            </span>
            <img :src="batch.image" alt="Foto do animal" />
            <div v-if="batch.is_sold" class="animal-sold">Vendido</div>
          </div>
        </swiper-slide>

        <swiper-slide v-for="(animal, index) in batch.animals" :key="index">
          <div class="card-background genealogy" />
          <div class="additional-infos">
            <span v-if="animal.register" class="mr-3">
              {{ animal.register }}
            </span>
            <span v-if="animal.iabcz"> iABCZ: {{ animal.iabcz }} </span>
          </div>

          <SelectionGenealogy :animal="animal.genealogy" />
        </swiper-slide>
      </swiper>

      <swiper
        v-else
        :pagination="{ clickable: true }"
        :slidesPerView="1"
        loop
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
      >
        <span v-if="batch.iabcz" class="card-iabcz">
          iABCZ: {{ batch.iabcz }}
        </span>
        <div class="swiper-button-content">
          <div class="swiper-button-next">
            <ChevronRight />
          </div>
          <div class="swiper-button-prev">
            <ChevronLeft />
          </div>
        </div>

        <swiper-slide>
          <div class="card-image">
            <div class="card-background" />
            <img :src="batch.image" alt="Foto do animal" />
            <div v-if="batch.is_sold" class="animal-sold">Vendido</div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="card-background genealogy" />
          <SelectionGenealogy :animal="batch.genealogy" />
        </swiper-slide>
      </swiper>

      <div class="card-text" :class="batch.is_sold ? 'sold' : ''">
        <p class="card-infos">
          Lote {{ batch.batch_number }} - {{ batch.title }}
        </p>
        <p class="card-provider">
          {{ providerName?.toUpperCase() }}
          {{ batch.state && `(${batch.state})` }}
        </p>
      </div>

      <div class="card-action" :class="batch.is_sold ? 'sold' : ''">
        <div
          v-if="batch.event_type.includes('Leilão')"
          class="card-time"
          :class="batch.date.highlight ? 'emphasize' : ''"
        >
          <GavelIcon />
          <span v-if="batch.is_sold"> Lote arrematado</span>
          <span v-else>Arremate {{ batch.date.value }}</span>
        </div>

        <div v-else class="card-prices">
          <p v-if="batch.is_sold" class="installments">Lote Vendido</p>
          <div v-else-if="batch.price == 0" class="installments">
            <p>Encerra em {{ batch.remaining_time }}</p>
          </div>
          <div v-else>
            <p class="installments">
              {{ batch.installments }}x
              <span>{{ installmentsPerAnimal }}</span>
              {{ batch.animals_quantity > 1 ? "/animal" : "" }}
            </p>
            <p class="total">
              Total de {{ numberToCurrency(batch.total_price) }}
            </p>
          </div>
        </div>

        <button class="button is-warning">Ver detalhes</button>
      </div>
    </div>
  </a>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper";

import { numberToCurrency } from "utils/currencyConverter";
import SelectionGenealogy from "./SelectionGenealogy.vue";

import ChevronLeft from "icons/cached/chevron_left.svg";
import ChevronRight from "icons/cached/chevron_right.svg";
import GavelIcon from "icons/cached/gavel.svg";

import { computed, onMounted } from "vue";

SwiperCore.use([Pagination, Navigation]);
export default {
  components: {
    Swiper,
    SwiperSlide,
    SelectionGenealogy,
    ChevronRight,
    ChevronLeft,
    GavelIcon,
  },
  props: {
    batch: Object,
    page: String,
    section: String,
  },
  setup(props) {
    const installmentsPerAnimal = (
      props.batch.price_per_animal / props.batch.installments
    ).toLocaleString("pt-br", { maximumFractionDigits: 0 });

    const providerName = computed(() => {
      if (Array.isArray(props.batch.provider_name)) {
        return props.batch.provider_name.length > 1
          ? "DIVERSOS FORNECEDORES"
          : props.batch.provider_name[0];
      } else {
        return props.batch.provider_name;
      }
    });

    onMounted(() => {
      const link_id = `#productId${props.batch.id}`;

      mixpanel.track_links(link_id, "Product Clicked", function () {
        return {
          Page: props.page,
          Section: props.section,
          ...props.batch.mixpanel_data,
        };
      });
    });

    return {
      numberToCurrency,
      installmentsPerAnimal,
      providerName,
    };
  },
};
</script>
