<template>
  <div
    class="floating-auction-header is-hidden-desktop"
    :class="{ active: open }"
  >
    <div class="columns is-mobile is-vcentered is-marginless is-variable is-2">
      <div class="column is-narrow">
        <div class="cover is-clipped">
          <img :src="auction.cover" :alt="'Banner - ' + auction.title" />
        </div>
      </div>
      <div class="column is-9-mobile">
        <div class="floating-auction-header-text">
          <div class="auction-date is-uppercase has-text-weight-medium">
            {{ auction.day }} de {{ auction.month }}, às {{ auction.time }}
          </div>
          <div class="auction-title has-text-weight-bold">
            {{ auction.title }}
          </div>
        </div>
      </div>
      <div class="column is-flex is-justify-content-flex-end">
        <ArrowDropDownIcon />
      </div>
    </div>
  </div>

  <div
    class="floating-auction-bottom has-text-centered is-hidden-desktop"
    :class="{ active: open }"
  >
    <div class="columns is-mobile is-marginless">
      <div class="column">
        <AuctionRegistrationButton
          customClass="is-warning is-small mb-2"
          cta="Tenho interesse"
          currentFlow="identification"
          trackSection="Floating Bar"
          :mixpanelData="mixpanelData"
          :trackData="trackData"
          :page="page"
          :name="name"
          :email="email"
          :phone="phone"
          :lpConversion="lpConversion"
        />
      </div>
    </div>
  </div>
  <AuctionRegistrationModal :auction="auctionRegistrationInfo" />
</template>

<script>
import ArrowDropDownIcon from "icons/cached/expand_more.svg";
import AuctionRegistrationButton from "./AuctionRegistrationButton.vue";
import AuctionRegistrationModal from "./AuctionRegistrationModal.vue";
import { ref, onMounted, onUnmounted } from "vue";

export default {
  components: {
    ArrowDropDownIcon,
    AuctionRegistrationButton,
    AuctionRegistrationModal,
  },
  props: {
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
    trackData: Object,
    auctionTags: Array,
    auction: Object,
    mixpanelData: Object,
    page: String,
    auctionRegistrationInfo: Object,
  },
  setup(props) {
    const open = ref(false);

    const whatsappAuctionUrl = `whatsapp://send?text=${encodeURI(
      `Veja na erural ${props.auction.title} em: `,
    )}%20${props.auction.url}`;

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const handleScroll = (e) => {
      const scrollY = window.scrollY;

      if (scrollY >= 400) {
        open.value = true;
      } else {
        open.value = false;
      }
    };

    return {
      open,
      whatsappAuctionUrl,
    };
  },
};
</script>
