<template>
  <div class="column" :class="classes">
    <a
      :id="
        batch.animal_type == 'Sêmen'
          ? `genexProductId${productId}`
          : `productId${productId}`
      "
      class="card-link"
      :href="
        batch.product_url ||
        batches_show_url(batch.auction_url_friendly, batch.url_friendly)
      "
    >
      <div class="card product" :class="{ pure: batch.genetic_type }">
        <div class="card-image">
          <figure class="image is-5by3">
            <img
              :src="batch.cover_url"
              :alt="batch.title"
              :class="{ scale: batch.cover_video }"
            />
            <div
              v-show="!batch.available"
              class="image-overlay has-text-weight-bold"
            >
              VENDIDO
            </div>
          </figure>
          <div
            v-if="batch.event_type == 'Sêmen' && !!batch.is_pregcheck"
            class="pregcheck-icon"
          >
            <PregcheckIcon />
          </div>
        </div>
        <div
          class="card-content"
          :class="{
            auction: batch.event_type.includes('Leilão'),
            shopping:
              batch.event_type == 'Shopping' ||
              batch.event_type == 'Venda permanente',
            semen: batch.event_type == 'Sêmen',
          }"
        >
          <div class="content">
            <div class="tags">
              <span v-if="batch.event_type != 'Sêmen'" class="tag">
                Lote {{ batch.number }}
              </span>

              <span v-if="batch.iabcz" class="tag">
                IABCZ: {{ Number(batch.iabcz).toFixed(1) }}
              </span>
            </div>

            <div v-if="batch.event_type == 'Sêmen'" class="badge-circle">
              <SemenIcon />
            </div>

            <div v-else-if="batch.is_deca_one" class="badge-deca">
              <Crown />
              <span>DECA 1</span>
            </div>

            <span v-if="batch.animal_type == 'Sêmen'" class="locator">
              Loja GENEX
            </span>
            <span v-else class="locator">
              {{
                batch.shipping == "free"
                  ? batch.auction_title
                  : batch.full_location
              }}
            </span>

            <span class="title">
              {{ batch.title }}
            </span>
            <template v-if="batch.genetic_type">
              <span
                v-if="batch.provider && !batch.biddable_shopping"
                class="promotion"
              >
                Por
                <b>{{
                  Array.isArray(batch.provider)
                    ? batch.provider.length > 1
                      ? "Diversos fornecedores"
                      : batch.provider[0]
                    : batch.provider
                }}</b>
              </span>
              <span
                v-if="batch.shipping == 'free'"
                class="promotion"
                :class="batch.biddable_shopping ? 'biddable-shopping' : ''"
              >
                <b>Frete grátis*</b>
              </span>
              <span
                v-if="batch.shipping == 'facilitated'"
                class="promotion"
                :class="batch.biddable_shopping ? 'biddable-shopping' : ''"
              >
                <b>Frete facilitado</b>
              </span>
              <span
                v-if="batch.shipping == 'by_buyer'"
                class="promotion"
                :class="batch.biddable_shopping ? 'biddable-shopping' : ''"
              >
                <b>Frete por conta do comprador</b>
              </span>
            </template>

            <span v-if="batch.biddable_shopping" class="promotion">
              <AuctionCountdown
                :endDate="batch.auction_end_date"
                label="Encerra em"
              />
            </span>

            <template v-else-if="batch.animal_type == 'Reposição'">
              <span
                v-if="batch.weight_avg && batch.weight_avg != '0'"
                class="promotion"
              >
                Peso Médio: {{ batch.weight_avg }}{{ batch.weight_unit }}
              </span>
              <span v-if="batch.certificated" class="certificate">
                <VerifiedUserIcon />
                <span class="is-hidden-tablet">Certificado</span>
                <span class="is-hidden-mobile">Certificado pela erural</span>
              </span>
            </template>

            <template v-else-if="batch.animal_type == 'Sêmen'">
              <span
                v-if="
                  batch.suggested_price && batch.suggested_price < batch.price
                "
                class="promotion genex"
              >
                Promoção
              </span>
              <span v-else class="promotion">
                {{ batch.breed }}
              </span>
              <span class="promotion">
                Frete <b>grátis</b> para {{ batch.free_shipping_doses }} doses
              </span>
            </template>
          </div>
        </div>
        <div
          v-if="
            (batch.event_type == 'Shopping' ||
              batch.event_type == 'Venda permanente') &&
            batch.price
          "
          class="card-footer"
        >
          <div>
            <span class="price">
              <span v-if="batch.installments > 1">
                {{ batch.installments }}x
              </span>
              {{ price }}
              <span
                v-if="batch.animals_count > 1"
                class="dose-text has-text-weight-bold"
              >
                / animal
              </span>
            </span>
            <span class="legend">
              <span
                v-if="batch.animals_count > 1 || batch.installments > 1"
                :class="{ 'has-text-warning': batch.has_discount }"
              >
                <span class="is-hidden-mobile"> Total de </span>
                {{ numberToCurrency(batch.price) }}
              </span>
              <span :class="{ 'has-text-warning': batch.commission == 0 }">
                + {{ batch.commission }}% de comissão
              </span>
            </span>
          </div>
        </div>
        <div
          v-else-if="batch.event_type.includes('Leilão') || !batch.price"
          class="card-footer"
        >
          <button class="button is-outlined is-warning is-fullwidth">
            Veja o Lote
          </button>
        </div>
        <div v-else class="card-footer">
          <div class="footer-ellipsis">
            <span
              v-if="
                batch.suggested_price && batch.suggested_price < batch.price
              "
              class="price promotion-price"
            >
              R$ {{ Math.round(batch.suggested_price) }}
            </span>
            <span class="price">
              R$ {{ Math.round(batch.price) }}
              <span class="dose-text has-text-weight-bold">
                / {{ batch.min_quantity }} doses
              </span>
            </span>
            <span class="legend has-text-warning">
              até 8x no cartão ou boleto
            </span>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { numberToCurrency } from "utils/currencyConverter";
import { batches_show_url } from "utils/routes";

import VerifiedUserIcon from "icons/cached/verified_user.svg";
import SemenIcon from "icons/cached/semen.svg";
import PregcheckIcon from "images/genex/pregcheck.svg";
import Crown from "images/crown.svg";

import AuctionCountdown from "../auction/AuctionCountdown.vue";

import { onMounted, computed } from "vue";

export default {
  components: {
    VerifiedUserIcon,
    SemenIcon,
    PregcheckIcon,
    Crown,
    AuctionCountdown,
  },
  props: {
    batch: Object,
    classes: {
      type: Array,
      default: () => ["is-6-mobile", "is-4-tablet", "is-3-widescreen"],
    },
    section: String,
    page: String,
  },
  setup(props) {
    const productId = props.batch.id;
    const price = computed(() => {
      return numberToCurrency(
        props.batch.price_per_animal / props.batch.installments,
      ).split(",")[0];
    });

    onMounted(() => {
      let link_id;

      if (props.batch.animal_type == "Sêmen") {
        link_id = `#genexProductId${productId}`;
      } else {
        link_id = `#productId${productId}`;
      }

      mixpanel.track_links(link_id, "Product Clicked", function () {
        return {
          Page: props.page,
          Section: props.section,
          ...props.batch.mixpanel_data,
        };
      });
    });

    return {
      batches_show_url,
      numberToCurrency,
      price,
      productId,
    };
  },
};
</script>
