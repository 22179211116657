<script>
// SHARED
import AppInput from "./ui/AppInput.vue";
import PasswordInput from "./ui/PasswordInput.vue";
import FlashMessage from "./ui/FlashMessage.vue";
import MultipleSelect from "./ui/MultipleSelect.vue";
import SingleSelect from "./ui/SingleSelect.vue";

// GENERAL
import ExampleComponent from "./ExampleComponent.vue";
import HunterSharePanel from "./ui/HunterSharePanel.vue";
import LiveAuctionModal from "./live_auction/LiveAuctionModal.vue";
import ChicoPromotion from "./ui/ChicoPromotion.vue";
import DelayModal from "./ui/DelayModal.vue";

// HEADER
import SearchField from "./header/SearchField.vue";
import UserInfos from "./header/UserInfos.vue";
import MobileHeader from "./header/MobileHeader.vue";

// SEARCH
import SearchPage from "./search/index.vue";

// AUCTION
import AuctionInfosMobile from "./auction/AuctionInfosMobile.vue";
import AuctionBanner from "./auction/AuctionBanner.vue";
import AuctionPreRegistrationForm from "./auction/AuctionPreRegistrationForm.vue";
import AuctionFloatingInfo from "./auction/AuctionFloatingInfo.vue";
import AuctionShareMobile from "./auction/AuctionShareMobile.vue";
import AuctionViewingTrack from "./auction/AuctionViewingTrack.vue";
import AuctionConditions from "./auction/AuctionConditions.vue";
import AuctionConditionsLp from "./auction/AuctionConditionsLp.vue";
import AuctionPreRegInfo from "./auction/AuctionPreRegInfo.vue";
import AuctionPreRegModal from "./auction/AuctionPreRegModal.vue";
import AuctionPaymentModal from "./auction/AuctionPaymentModal.vue";
import AuctionShippingModal from "./auction/AuctionShippingModal.vue";
import AuctionInfoModal from "./auction/AuctionInfoModal.vue";
import AuctionBatches from "./auction/AuctionBatches.vue";
import AuctionRegistrationButton from "./auction/AuctionRegistrationButton.vue";
import AuctionRegistrationModal from "./auction/AuctionRegistrationModal.vue";
import AuctionRegistrationModalIdentification from "./auction/AuctionRegistrationModalIdentification.vue";
import AuctionRegistrationModalConfirm from "./auction/AuctionRegistrationModalConfirm.vue";
import AuctionRegistrationModalInitial from "./auction/AuctionRegistrationModalInitial.vue";
import AuctionRegistrationModalBid from "./auction/AuctionRegistrationModalBid.vue";
import AuctionRegistrationModalMessage from "./auction/AuctionRegistrationModalMessage.vue";
import AuctionRegistrationModalProposal from "./auction/AuctionRegistrationModalProposal.vue";
import AuctionRegistrationModalAuctionCard from "./auction/AuctionRegistrationModalAuctionCard.vue";
import AuctionRegistrationModalBatchCard from "./auction/AuctionRegistrationModalBatchCard.vue";
import AuctionCountdown from "./auction/AuctionCountdown.vue";
import FeaturedAuctionCard from "./auction/FeaturedAuctionCard.vue";

// BATCHES
import ProductCard from "./product/ProductCard.vue";
import SelectionCard from "./selection/SelectionCard.vue";
import BatchCarousel from "./batch/BatchCarousel.vue";
import BatchAnimals from "./batch/BatchAnimals.vue";
import BatchAuctionDetails from "./batch/BatchAuctionDetails.vue";
import DescribeAnimalButton from "./batch/DescribeAnimal/DescribeAnimalButton.vue";
import DescribeAnimalModal from "./batch/DescribeAnimal/DescribeAnimalModal.vue";

// REELS
import Reels from "./reels/Reels.vue";

// REGISTRATION FLOW
import RegistrationFlowModal from "./registration_flow/RegistrationFlowModal.vue";
import SoldAuctionPreRegistrationForm from "./auction/SoldAuctionPreRegistrationForm.vue";
import ActiveAuctionPreRegistrationForm from "./auction/ActiveAuctionPreRegistrationForm.vue";

// USER
import UserAuth from "./user/UserAuth.vue";
import UserForm from "./user/form/UserForm.vue";
import ProfilePicture from "./user/ProfilePicture.vue";
import UserNavMenu from "./user/UserNavMenu.vue";
import UpdatePasswordModal from "./user/UpdatePasswordModal.vue";
import CreateDocumentModal from "./user/form/CreateDocumentModal.vue";
import ProfileHeader from "./user/profile/ProfileHeader.vue";
import PurchaseHistory from "./user/profile/PurchaseHistory.vue";
import PurchaseProfile from "./user/profile/purchase_preferences/PurchaseProfile.vue";
import PreferenceChoices from "./user/profile/purchase_preferences/PreferenceChoices.vue";
import RedirectCards from "./user/profile/RedirectCards.vue";

// CONTACT
import ContactDesktopForm from "./contact/DesktopForm.vue";
import ContactMobilePage from "./contact/ContactMobilePage.vue";

// CRAWLED AUCTIONS
import CrawledAuctionFilters from "./crawled_auction/CrawledAuctionFilter.vue";
import CrawledAuctionMobileFilters from "./crawled_auction/CrawledAuctionMobileFilters.vue";
import CrawledAuctionModalFilters from "./crawled_auction/CrawledAuctionModalFilters.vue";
import CrawledAuctionContent from "./crawled_auction/CrawledAuctionContent.vue";
import CrawledAuctionContentFeatured from "./crawled_auction/CrawledAuctionContentFeatured.vue";

// SELL WITH US
import SellWithUsForm from "./sell_with_us/SellForm.vue";

// IDEAL BULL
import IdealBullPage from "./ideal_bull/index.vue";

// HOME
import HomeFeaturedAuctions from "./app/HomeFeaturedAuctions.vue";

// CUSTOMER
import CustomerReferralLinkInput from "./customer/CustomerReferralLinkInput.vue";
import CustomerReferralWhatsappShareButton from "./customer/CustomerReferralWhatsappShareButton.vue";

// RECOMMENDATION
import RecommendationPage from "./recommendation/RecommendationPage.vue";

// SELECTION
import SelectionPage from "./selection/SelectionPage.vue";

// ALERT SUBSCRIPTION
import AlertUnsubscribePage from "./alert_subscription/AlertUnsubscribePage.vue";

// PURCHASE PREFERENCES
import PurchaseRecommendations from "./purchase_preference/PurchaseRecommendations.vue";

export default {
  components: {
    // SHARED
    AppInput,
    PasswordInput,
    FlashMessage,
    MultipleSelect,
    SingleSelect,

    // GENERAL
    ExampleComponent,
    HunterSharePanel,
    LiveAuctionModal,
    ChicoPromotion,
    DelayModal,

    // HEADER
    SearchField,
    UserInfos,
    MobileHeader,

    // SEARCH
    SearchPage,

    // AUCTION
    AuctionInfosMobile,
    AuctionBanner,
    AuctionPreRegistrationForm,
    AuctionFloatingInfo,
    AuctionShareMobile,
    AuctionViewingTrack,
    AuctionConditions,
    AuctionConditionsLp,
    AuctionPreRegInfo,
    AuctionPreRegModal,
    AuctionPaymentModal,
    AuctionShippingModal,
    AuctionInfoModal,
    AuctionBatches,
    AuctionRegistrationButton,
    AuctionRegistrationModal,
    AuctionRegistrationModalIdentification,
    AuctionRegistrationModalConfirm,
    AuctionRegistrationModalInitial,
    AuctionRegistrationModalBid,
    AuctionRegistrationModalMessage,
    AuctionRegistrationModalProposal,
    AuctionRegistrationModalAuctionCard,
    AuctionRegistrationModalBatchCard,
    AuctionCountdown,
    FeaturedAuctionCard,

    // BATCHES
    ProductCard,
    SelectionCard,
    BatchCarousel,
    BatchAnimals,
    BatchAuctionDetails,
    DescribeAnimalButton,
    DescribeAnimalModal,

    // REELS
    Reels,

    // REGISTRATION FLOW
    RegistrationFlowModal,
    SoldAuctionPreRegistrationForm,
    ActiveAuctionPreRegistrationForm,

    // USER
    UserAuth,
    UserForm,
    ProfilePicture,
    UserNavMenu,
    UpdatePasswordModal,
    CreateDocumentModal,
    PurchaseHistory,
    ProfileHeader,
    PurchaseProfile,
    PreferenceChoices,
    RedirectCards,
    UserForm,

    // CONTACT
    ContactDesktopForm,
    ContactMobilePage,

    // CRAWLED AUCTIONS
    CrawledAuctionFilters,
    CrawledAuctionMobileFilters,
    CrawledAuctionModalFilters,
    CrawledAuctionContent,
    CrawledAuctionContentFeatured,

    // SELL WITH US
    SellWithUsForm,

    // IDEAL BULL
    IdealBullPage,

    // HOME
    HomeFeaturedAuctions,

    // CUSTOMER
    CustomerReferralLinkInput,
    CustomerReferralWhatsappShareButton,

    // RECOMMENDATION
    RecommendationPage,

    // SELECTION
    SelectionPage,

    // ALERT SUBSCRIPTION
    AlertUnsubscribePage,

    // PURCHASE PREFERENCES
    PurchaseRecommendations,
  },
  setup() {
    return;
  },
};
</script>
