<template>
  <div class="auction-buttons-container">
    <AuctionRegistrationButton
      customClass="is-warning is-small mb-2"
      cta="Tenho interesse"
      currentFlow="identification"
      trackSection="Infos Container"
      :mixpanelData="mixpanelData"
      :trackData="trackData"
      :page="page"
      :name="name"
      :email="email"
      :phone="phone"
      :lpConversion="lpConversion"
    />
    <button
      class="button is-warning is-outlined is-fullwidth mb-4 is-hidden-touch is-small"
      @click="() => (openInfoModal = true)"
    >
      Mais informações
    </button>
  </div>
  <AuctionPreRegModal
    :name="name"
    :email="email"
    :phone="phone"
    :page="page"
    :formDisplay="formDisplay"
    :section="section"
    :lpConversion="lpConversion"
    :trackData="trackData"
    :auctionTags="auctionTags"
    :mixpanelData="mixpanelData"
    :open="openPreRegModal"
    :bannerBtn="false"
    @close="() => (openPreRegModal = false)"
  />

  <AuctionRegistrationModal :auction="auctionRegistrationInfo" />

  <AuctionInfoModal
    :open="openInfoModal"
    :auction="auction"
    :auctionFiles="auctionFiles"
    :mixpanelData="mixpanelData"
    @close="() => (openInfoModal = false)"
  />
</template>
<script>
import AuctionPreRegModal from "./AuctionPreRegModal.vue";
import AuctionInfoModal from "./AuctionInfoModal.vue";
import AuctionRegistrationModal from "./AuctionRegistrationModal.vue";
import AuctionRegistrationButton from "./AuctionRegistrationButton.vue";

import { preRegistrationState } from "components/registration_flow/preRegistrationContext";

import { buttonProps } from "utils/buttonProps";

import { ref, toRefs, watch } from "vue";

export default {
  components: {
    AuctionPreRegModal,
    AuctionInfoModal,
    AuctionRegistrationModal,
    AuctionRegistrationButton,
  },
  props: {
    name: String,
    email: String,
    phone: String,
    lpConversion: Object,
    trackData: Object,
    auctionTags: Array,
    auction: Object,
    auctionFiles: Object,
    mixpanelData: Object,
    page: String,
    formDisplay: String,
    section: String,
    auctionRegistrationInfo: Object,
  },
  setup(props) {
    const openPreRegModal = ref(false);
    const openInfoModal = ref(false);

    const { conversion } = toRefs(preRegistrationState);

    watch(conversion, () => {
      openPreRegModal.value = conversion ? false : true;
    });

    const openModalAndTrack = (e) => {
      openPreRegModal.value = true;

      let element = e.currentTarget;

      let eventName = "Event - CTA (#1) Clicked";

      mixpanel.track(eventName, {
        ...props.mixpanelData,
        ...buttonProps(element),
      });
    };

    return {
      openPreRegModal,
      openInfoModal,
      openModalAndTrack,
    };
  },
};
</script>
